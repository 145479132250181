import {
  MEDIA_CLICKED,
  onEvent,
  SHOW_HIDDEN_TEASER,
} from '@fec/assets/js/utils/event';
import { Trans } from '@fec/assets/js/utils/trans';
import Urn from '@fec/frontend/foundation/client/urn';
import { readUrnFromMetaTag } from '@fec/assets/js/utils/meta-tags';
import { getMediaProgressList } from '@fec/assets/js/utils/media-progress';

const MEDIA_PROGRESS_CLASS = 'js-media-progress';
const MEDIA_PROGRESS_CONTAINER_CLASS = 'js-media-progress-container';

export const shouldShowMediaProgress = () => {
  const currentPageUrn = readUrnFromMetaTag();
  const relevantLandingPages = [
    'urn:rtr:landingpage:5698682', // Audioteca
    'urn:srf:landingpage:17597814', // Audio & Podcasts
  ];

  // show progress bar & co. on certain landingpages and on all other page
  // types (articles, radio episodes, ...)
  let showProgressInfo = true;
  if (Urn.getType(currentPageUrn) === 'landingpage') {
    showProgressInfo = relevantLandingPages.includes(currentPageUrn);
  }

  return showProgressInfo;
};

/**
 * Purpose:
 * Add a progress bar below media or media teasers that have been started but
 * not finished.
 */
export const init = () => {
  if (shouldShowMediaProgress()) {
    // on page load, add the progress bars
    visualizeMediaProgress();

    // when teasers are added/shown, we might need to add more
    onEvent({
      eventName: SHOW_HIDDEN_TEASER,
      eventHandler: () => visualizeMediaProgress(),
    });

    // when a medium turns active, make sure it doesn't get a progress bar and
    // that a potential previously played medium is updated correctly
    onEvent({
      eventName: MEDIA_CLICKED,
      eventHandler: ({ detail }) => onMediaClicked(detail.assetId),
    });
  }
};

const onMediaClicked = (playingUrn) => {
  // remove the active medium's URN from the progress list, so that no progress
  // bar is rendered
  const progressList = getMediaProgressList().filter(
    ({ urn }) => playingUrn !== urn,
  );

  visualizeMediaProgress(progressList);
};

const visualizeMediaProgress = (progressList) => {
  // remove previously added progress info so this script can be run again
  $(`.${MEDIA_PROGRESS_CLASS}`).remove();

  // get list from localstorage if not provided
  if (!progressList) {
    progressList = getMediaProgressList();
  }

  // go through all progress entries and...
  progressList.forEach((mediaProgress) => {
    // get all elements that are either
    // * a "direct" teaser of a medium (data-urn is the same as the medium's URN)
    // * a teaser for a medium (data-asset-urn is the same as the medium's URN)
    // * a medium (data-assetid, e.g. in an article)
    const $relatedElements = $(`
      [data-urn="${mediaProgress.urn}"],
      [data-asset-urn="${mediaProgress.urn}"],
      [data-assetid="${mediaProgress.urn}"]
    `);

    // for each of these elements, add the progress info
    $relatedElements.each((index, element) =>
      addPlayProgressInfo($(element), mediaProgress),
    );
  });
};

/**
 * Add progress information to an element. If the duration (as percentage) is
 * known, a progress bar is added, otherwise only the time indicator enhanced
 * with the current progress.
 *
 * @param {jQuery.element} $element
 * @param {Object} mediaProgressInfo
 */
const addPlayProgressInfo = ($element, mediaProgressInfo) => {
  let { seconds, duration } = mediaProgressInfo;
  if ($element.data('starttime')) {
    seconds = $element.data('starttime');
  }
  if (duration && duration > 0) {
    const progress = (seconds * 100) / duration;
    addMediaProgressInformation($element, progress);
  }

  addProgressToTimeIndicator($element, seconds);
};

/**
 * Add a progress bar to an element. For that, a child with a specific class
 * must be present.
 * If there's a media caption present on the element, a note for screenreaders
 * will be added as compensation for the visual bar.
 *
 * @param {jQuery.element} $element
 * @param {Number} progress (0 - 100)
 */
const addMediaProgressInformation = ($element, progress) => {
  // from 98% on a medium is considered finished!
  if (progress >= 98) {
    progress = 100;
  }

  $(`.${MEDIA_PROGRESS_CONTAINER_CLASS}`, $element).append(
    `<div class="media-still__progress ${MEDIA_PROGRESS_CLASS}">
      <div class="media-progress-bar">
        <div class="media-progress-bar__indicator" style="--media-progress-bar-progress: ${progress}"></div>
      </div>
    </div>`,
  );

  const roundedProgress = Math.round(progress);
  const text = new Trans().translatedWithPlaceHolder(
    'i18n:status:progress:heard',
    roundedProgress,
  );
  $('.media-caption', $element).append(
    `<div class="h-offscreen ${MEDIA_PROGRESS_CLASS}">${text}</div>`,
  );
};

/**
 * Changes duration indicator of media by including the played time,
 * e.g. from [02:10] to [01:30 / 02:10]
 *
 * @param {jQuery.element} $element
 * @param {Number} secondsPlayed
 */
const addProgressToTimeIndicator = ($element, secondsPlayed) => {
  // prepend initial seek position to media indicator
  const $timeIndicator = $('.js-media-time-indicator', $element);
  if ($timeIndicator) {
    $timeIndicator.prepend(
      `<span class="${MEDIA_PROGRESS_CLASS}">${getFormattedDuration(
        secondsPlayed,
      )} / </span>`,
    );
  }
};

/**
 * formats seconds into the same format as in twig filter
 * src/SRF/CMSSiteBundle/Twig/MediaExtension.php, e.g.
 * 1   --> 00:01
 * 60  --> 01:00
 * 100 --> 01:40
 * 420 --> 07:00
 *
 * @param {Number} durationInSeconds
 * @returns {string} formatted time string
 */
const getFormattedDuration = (durationInSeconds) => {
  let minutes = '' + Math.floor(durationInSeconds / 60);
  let seconds = '' + Math.floor(durationInSeconds % 60);
  return `${minutes.padStart(2, '0')}:${seconds.padStart(2, '0')}`;
};
