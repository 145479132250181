import ClientStorage from '@fec/frontend/foundation/client/storage';

const MEDIA_PROGRESS_STORAGE_KEY = 'srf:item:media:progress';
const MEDIA_PROGRESS_LIMIT = 150;

export const getMediaProgressList = () =>
  ClientStorage.getItemJsonParsed(MEDIA_PROGRESS_STORAGE_KEY, []);

export const setMediaProgressList = (list) =>
  ClientStorage.setItemJsonStringified(MEDIA_PROGRESS_STORAGE_KEY, list);

export const getMediumProgress = (urn) => {
  const list = getMediaProgressList();
  return list.find((entry) => entry.urn === urn);
};

export const setMediumProgress = (urn, seconds, duration) => {
  let list = getMediaProgressList();

  // remove current item, if existing
  list = list.filter((entry) => entry.urn !== urn);

  // add new/updated entry to the end of the list
  list.push({
    urn,
    seconds,
    duration,
  });

  // make sure list doesn't get too long (this removes the oldest entries)
  list = list.slice(-MEDIA_PROGRESS_LIMIT);

  setMediaProgressList(list);
};

export const getMediumProgressPercentage = (urn) => {
  const progress = getMediumProgress(urn);
  if (!progress) {
    return 0;
  }

  const { seconds, duration } = progress;
  const percentage = (seconds / duration) * 100;
  // make sure percentage is always between 0 and 100
  const clampedPercentage = Math.min(Math.max(percentage, 0), 100);

  return clampedPercentage;
};

/**
 * Returns "04:20" formatted string for the progress of a medium, if available.
 *
 * @param urn
 * @returns {string|null}
 */
export const getMediumProgressFormatted = (urn) => {
  const progress = getMediumProgress(urn);
  if (!progress) {
    return null;
  }

  const progressInSeconds = Math.floor(progress.seconds);
  const minutes = Math.floor(progressInSeconds / 60);
  const secondsLeft = progressInSeconds % 60;

  const formattedMinutes = (minutes + '').padStart(2, '0');
  const formattedSeconds = (secondsLeft + '').padStart(2, '0');

  return `${formattedMinutes}:${formattedSeconds}`;
};
