import { setFocus } from '@fec/frontend/foundation/a11y';
import ClientStorage from '@fec/frontend/foundation/client/storage';
import { Trans } from '@fec/assets/js/utils/trans.js';
import { KEYCODES } from '@fec/frontend/foundation/utils/keycodes';
import { onEvent, SET_SEARCH_INACTIVE_EVENT } from '@fec/assets/js/utils/event';

export function init() {
  $('.js-search')
    .filter((_, element) => !$(element).data('search-initialized'))
    .each((_, element) => {
      new SrfSearch(element);

      // mark element, so that it won't be initialized again by this module
      $(element).data('search-initialized', true);
    });
}

const DEFAULT_MAX_SUGGESTIONS = 7;
const DEFAULT_MIN_SEARCH_LENGTH = 2;

const ACTIVE_CLASS = 'search--active';
const OUTSIDE_CLICK_LISTENER_NAME = 'mousedown.search-destroyer';
const LOCAL_STORAGE_KEY = 'srf:search:history';

export class SrfSearch {
  constructor(element, options) {
    this.$element = $(element);
    this.$inputField = this.$element.find('.js-search-input');
    this.$searchResults = this.$element.find('.js-search-results');
    this.$closeIcon = this.$element.find('.js-search-close');
    this.trans = new Trans(element).getAll();

    this.options = $.extend(
      {},
      {
        maxSuggestionCount: DEFAULT_MAX_SUGGESTIONS,
        minSearchLength: DEFAULT_MIN_SEARCH_LENGTH,
      },
      options,
    );

    this.typeaheadUrl = this.$element.attr('data-typeahead-url');
    this.clickedSuggestionsEnabled = this.$element.data('suggestion-history');
    this.typeaheadData = null;
    this.suggestionUrl = '';
    this.currTimeout = null;

    // search field is hidden before document.ready (events firing before document.ready can get lost)
    this.$inputField.addClass('search__input--loaded');
    this.registerListeners();
  }

  registerListeners() {
    // e.g. in the search modal, the whole element will be focused -> set focus to inputfield
    this.$element.on('focus', () => {
      setTimeout(() => {
        this.$inputField.focus();
      }, 0);
    });

    this.$element.on('submit', () => {
      if (this.$inputField.val().trim() == '') {
        return false;
      }
    });

    this.$inputField
      .on('focus', () => {
        this.setSearchActive();

        if (
          this.clickedSuggestionsEnabled &&
          ClientStorage.isLocalStorageAvailable() &&
          ClientStorage.hasItem(LOCAL_STORAGE_KEY) &&
          this.$inputField.val() === ''
        ) {
          const storedResults =
            ClientStorage.getItemJsonParsed(LOCAL_STORAGE_KEY);
          this.renderResults(storedResults, '', true);
        }
      })
      .on('keyup', (e) => {
        this.onKeyUp(e);
      })
      .on('keydown', (e) => {
        this.onKeyDown(e);
      })
      .on('click', (e) => {
        e.stopPropagation();
      });

    this.$closeIcon
      .on('click', (e) => {
        e.preventDefault();
        this.setSearchInactive();
        this.clearInput();
      })
      .on('keydown', (e) => {
        this.onCloseIconKeyDown(e);
      });

    this.$searchResults.on('keydown', (e) => {
      this.onResultsKeyDown(e);
    });

    onEvent({
      eventName: SET_SEARCH_INACTIVE_EVENT,
      eventHandler: () => this.setSearchInactive(),
    });
  }

  onKeyUp(e) {
    switch (e.keyCode) {
      case KEYCODES.tab:
        break;
      case KEYCODES.escape:
        this.$inputField.blur();
        this.setSearchInactive();
        this.clearInput();
        break;
      default: {
        const query = this.$inputField.val().toString().toLowerCase();
        this.lookup(query);
      }
    }
  }

  onKeyDown(e) {
    switch (e.keyCode) {
      case KEYCODES.tab:
        if (e.shiftKey) {
          // Shift-Tabbing out of the search component
          this.setSearchInactive();
          this.clearInputIfAllowed();
        }
        break;
      case KEYCODES.up:
        this.moveUpInMenu();
        e.preventDefault();
        break;
      case KEYCODES.down:
        this.moveDownInMenu();
        e.preventDefault();
        break;
      default:
        break;
    }
  }

  onCloseIconKeyDown(e) {
    switch (e.keyCode) {
      case KEYCODES.escape:
        this.setSearchInactive();
        this.clearInput();
        break;
      case KEYCODES.tab:
        if (this.$searchResults.children().length === 0) {
          this.setSearchInactive();
          this.clearInputIfAllowed();
        }
        break;
      default:
        break;
    }
  }

  onResultsKeyDown(e) {
    switch (e.keyCode) {
      case KEYCODES.escape:
        this.setSearchInactive();
        this.clearInputIfAllowed();
        break;
      case KEYCODES.tab:
        // tabbing away from the last result
        if ($(e.target).parents('li').is(':last-child') && !e.shiftKey) {
          this.setSearchInactive();
          this.clearInputIfAllowed();
        }
        break;
      case KEYCODES.up:
        this.moveUpInMenu();
        e.preventDefault();
        break;
      case KEYCODES.down:
        this.moveDownInMenu();
        e.preventDefault();
        break;
      default:
        break;
    }
  }

  setSearchActive() {
    if (this.$element.hasClass(ACTIVE_CLASS)) {
      return false;
    }

    this.$element.addClass(ACTIVE_CLASS);
    // Listen to clicks outside of the element --> deactivates this search component
    $(document).on(OUTSIDE_CLICK_LISTENER_NAME, (e) => {
      // Disable this search if the click was not a descendant of any .js-search or if it's a descendant of a different search component
      if (
        !$(e.target).parents('.js-search').length &&
        $(e.target).parents('.js-search') !== this.$element
      ) {
        this.setSearchInactive();
      }
    });

    if (this.typeaheadData === null) {
      $.getJSON(this.typeaheadUrl, (data) => {
        this.typeaheadData = data;

        if (
          this.clickedSuggestionsEnabled &&
          ClientStorage.isLocalStorageAvailable &&
          ClientStorage.hasItem(LOCAL_STORAGE_KEY)
        ) {
          let items = ClientStorage.getItemJsonParsed(LOCAL_STORAGE_KEY);
          items = items.filter((storedItem) => {
            return this.typeaheadData.find((fetchedItem) => {
              return (
                fetchedItem.name === storedItem.name &&
                fetchedItem.url === storedItem.url
              );
            });
          });

          ClientStorage.setItemJsonStringified(LOCAL_STORAGE_KEY, items);
        }
      });
    }

    this.$closeIcon.show();
  }

  setSearchInactive() {
    this.hideResults();
    this.$element.removeClass(ACTIVE_CLASS);
    $(document).off(OUTSIDE_CLICK_LISTENER_NAME);
    this.$element.find('.search-result__alert').empty();
  }

  moveUpInMenu() {
    let $suggestions = this.$searchResults.find('.search-result__link'),
      $currentlyFocused = $('.search-result__link:focus'),
      currentIndex = $suggestions.index($currentlyFocused),
      nextIndex = currentIndex - 1;

    if ($suggestions.length === 0) {
      return;
    }

    if (nextIndex < 0) {
      nextIndex = $suggestions.length - 1;
    }

    setFocus($suggestions.eq(nextIndex));
  }

  moveDownInMenu() {
    let $suggestions = this.$searchResults.find('.search-result__link'),
      $currentlyFocused = $('.search-result__link:focus'),
      currentIndex = $suggestions.index($currentlyFocused),
      nextIndex = currentIndex + 1;

    if ($suggestions.length === 0) {
      return;
    }

    if (nextIndex >= $suggestions.length) {
      nextIndex = 0;
    }

    setFocus($suggestions.eq(nextIndex));
  }

  hideResults() {
    this.$inputField.attr('aria-expanded', false);
    this.suggestionUrl = '';
    this.$element.find('.search-result__alert').empty();

    this.$searchResults
      .hide()
      .html('')
      .removeClass('search__results--showed-results');
  }

  clearInput() {
    this.$inputField.val('');
    this.$closeIcon.hide();
    this.suggestionUrl = '';
  }

  clearInputIfAllowed() {
    // clear input if data-keep-keyword-on-blur was not set
    if (this.$inputField.data('keep-keyword-on-blur') === undefined) {
      this.clearInput();
    }
  }

  lookup(query) {
    let results = [];

    if (this.typeaheadData === null) {
      return true;
    }

    if (query.length < this.options.minSearchLength) {
      this.hideResults();
      return true;
    }

    this.typeaheadData.forEach((item) => {
      const matchIndexName = item.name.toString().toLowerCase().indexOf(query);
      const matchIndexKey = item.keymatches
        .toString()
        .toLowerCase()
        .indexOf(query);
      let matchIndex = Math.max(matchIndexName, matchIndexKey);
      if (matchIndex >= 0) {
        results.push({
          name: item.name,
          type: item.showType,
          url: item.url,
          matchIndex: matchIndex,
        });
      }
    });

    if (results.length > 0) {
      results = results.slice(0, this.options.maxSuggestionCount);
      this.renderResults(results, query);
    } else {
      this.hideResults();
    }
  }

  /**
   * Renders the received search results into the results list.
   * For the Screen-Reader's sake it'll be rendered twice, once with the found substring highlighted and once readable.
   *
   * If we're replacing previously shown search results, make sure they're not animated anymore.
   *
   * @param {Object} results
   * @param {String} query
   * @param isStoredResults
   */
  renderResults(results, query, isStoredResults = false) {
    const wasAlreadyShowingResults =
      this.$searchResults.children('li').length > 0;
    const resultLength = results.length;

    this.$searchResults.html('');
    if (!isStoredResults) {
      $('<span>', { class: 'search__results-label' })
        .text(this.trans.suggestedShowsLabel)
        .appendTo(this.$searchResults);
    }

    results.map((result, index) => {
      let highlightedResult = this.highlightQuery(query, result.name);
      const inputData = this.$inputField.data('referrer-track');
      const $li = $('<li>', {
        class: `typeahead-suggestion ${
          wasAlreadyShowingResults ? 'typeahead-suggestion--no-animation' : ''
        } search__result`,
      });

      const $link = $('<a>', {
        class: `search-result__link search-result__link--${result.type}`,
        href: result.url,
        'data-referrer-track':
          'srg_override_mod1=search_suggestions&srg_override_mod2=' +
          inputData.split('srg_mod1=')[1] +
          '&srg_override_mod3=' +
          query +
          '&srg_override_mod4=' +
          resultLength +
          '-' +
          (index + 1),
      });

      $link.on('click', () => {
        this.$inputField.val('');

        if (
          this.clickedSuggestionsEnabled &&
          ClientStorage.isLocalStorageAvailable()
        ) {
          let storedResults = [];
          if (ClientStorage.hasItem(LOCAL_STORAGE_KEY)) {
            storedResults = ClientStorage.getItemJsonParsed(LOCAL_STORAGE_KEY);
          }

          storedResults = storedResults.filter((item) => {
            return item.name !== result.name && item.url !== result.url;
          });

          storedResults.unshift(result);
          storedResults = storedResults.slice(
            0,
            this.options.maxSuggestionCount,
          );
          ClientStorage.setItemJsonStringified(
            LOCAL_STORAGE_KEY,
            storedResults,
          );
        }
      });

      $('<span>', { role: 'presentation', 'aria-hidden': true })
        .append(highlightedResult)
        .appendTo($link);
      $('<span>', { class: 'h-offscreen' })
        .text(this.trans.showScreenreaderText + result.name)
        .appendTo($link);

      // for audio/video results, add a hidden text for screenreaders
      if (result.type === 'video' || result.type === 'audio') {
        $('<span>', { class: 'h-offscreen' })
          .text(
            result.type === 'video'
              ? this.trans.typeVideoScreenreaderText
              : this.trans.typeAudioScreenreaderText,
          )
          .appendTo($link);
      }

      $li.append($link);
      $li.appendTo(this.$searchResults);
    });

    if (!isStoredResults) {
      const inputData = this.$inputField.data('referrer-track');
      const $showAllLink = $('<a>', {
        href: this.$element.attr('action') + '?q=' + query,
        class:
          'search-result__link search-result__link--search search-result__link--border',
        'data-referrer-track':
          'srg_override_mod1=search_suggestions&srg_override_mod2=' +
          inputData.split('srg_mod1=')[1] +
          '&srg_override_mod3=' +
          query +
          '&srg_override_mod4=' +
          resultLength +
          '-show-all',
      }).text(this.trans.showAllResults);

      $showAllLink.appendTo(this.$searchResults);
    }

    this.$searchResults.show();

    // additional screenreader info (same behavior like meteo search field)
    let $result_alert = $('.search-result__alert');
    let result_html = `${results.length} ${this.$inputField.data(
      'result-alert-text',
    )} <span>${query}</span>`;

    if ($result_alert.length === 0) {
      this.$searchResults.before(
        `<span class="h-offscreen search-result__alert" role="alert">${result_html}</span>`,
      );
    } else {
      $result_alert.html(result_html);
    }

    this.$inputField.attr('aria-expanded', true);
  }

  /**
   * Replaces the query string in a search result with the same string in bold  e.g.
   * "Tag" in "Tagesschau" after searching for "Tag"
   *
   * @param {String} query
   * @param {String} name
   * @returns {String}
   */
  highlightQuery(query, name) {
    query = query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&'); // eslint-disable-line
    return name.replace(
      new RegExp('(' + query + ')', 'ig'),
      ($1, match) => `<strong>${match}</strong>`,
    );
  }
}
