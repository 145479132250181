export const getCollectionTitle = (collection) => {
  const headingEl = collection.querySelector('.js-collection-heading');
  return headingEl?.innerText?.trim() ?? '';
};

export const showCollection = (collectionElement) => {
  collectionElement.classList.remove('collection-ng--hidden');
};

export const addTeasersToCollection = (collection, teasers) => {
  const teaserList = collection.querySelector('.js-teaser-ng-list');
  if (!teaserList) {
    return;
  }

  const listToAdd = teasers
    .map(
      (teaser) =>
        `<li class="collection-ng__teaser-item js-teaser-ng-item">${teaser.trim()}</li>`,
    )
    .join('');

  teaserList.innerHTML += listToAdd;
};

// Problem: sometimes, teasers are rendered in the backend and not inside a
// collection's twig template, so the collection title isn't known (needed for
// tracking reasons).
// Solution: Get the collection title via JS and inject it
export const enhanceTeaserTrackingDataWithCollectionTitle = (collection) => {
  const collectionTitle = getCollectionTitle(collection);

  if (!collectionTitle) {
    return;
  }

  collection.querySelectorAll('.js-teaser-ng').forEach((teaserElement) => {
    const oldModalData = JSON.parse(teaserElement.dataset.ngModal);
    const newModalData = {
      ...oldModalData,
      eventTrack: {
        ...oldModalData.eventTrack,
        event_value: collectionTitle,
      },
    };
    teaserElement.dataset.ngModal = JSON.stringify(newModalData);
  });
};
