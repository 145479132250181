import { init as srfAnalytics } from './analytics/init';
import { init as deadmansSwitchInit } from './analytics/Deadman';

import { init as transInit } from '@fec/assets/js/utils/trans';
import { init as a11yInit } from '@fec/frontend/foundation/a11y';

import { init as subnavInit } from '@fec/frontend/modules/groups-and-lists/subnav/subnav';

import '@fec/frontend/foundation/utils/easings';
import '@fec/frontend/modules/containers/modal/modal';

import { init as popupInit } from '@fec/frontend/foundation/popup/popup';
import { init as imageNgInit } from '@fec/frontend/elements/media/image-ng/image-ng';
import { init as teaserNgInit } from '@fec/frontend/modules/content/teaser-ng/teaser-ng';
import { init as searchInit } from '@fec/frontend/elements/search/search/search';
import { init as srfSearchInit } from './srf-search';
import { init as flyingFocusInit } from '@fec/frontend/foundation/flying-focus';
import { init as srfStaticBannerInit } from '../../webview/js/srf-static-banner-init';
import { init as teaserInfoUpdaterInit } from '@fec/frontend/elements/text/teaser-info/teaser-info-updater';
import { init as srfBackgroundImageChangerInit } from '../../webview/js/srf-background-image-changer';
import { init as lazyloadInit } from '@fec/assets/js/utils/lazyload';
import { init as srfMeteoLazyloadInit } from '../../webview/js/srf-meteo-lazyload';
import { init as showDetailsInit } from '@fec/frontend/modules/content/show-detail/show-detail';
import { init as srfSongLog } from '@fec/frontend/modules/groups-and-lists/songlog/songlog';
import { init as srfThemeColorInit } from './srf-themeColor';
import { init as broadcastInfoInit } from '@fec/frontend/elements/text/broadcast-info/broadcast-info.js';
import { init as teaserLabelInit } from '@fec/frontend/elements/text/teaser-label/teaser-label.js';
import { init as radioActionBarInit } from '@fec/frontend/snowflakes/radio/radio-action-bar/radio-action-bar';
import { init as modalInit } from '@fec/frontend/modules/containers/modal/modal.js';
import { init as srfPodcastHistoryInit } from '../../webview/js/srf-podcast-history';
import { init as SwipeableAreaInit } from '@fec/frontend/modules/containers/swipeable-area/swipeable-area';
import { init as swipeableControlInit } from '@fec/frontend/modules/collections/collection-ng/collection-ng-swipeable';
import { init as srfMediaProgressDisplayInit } from '@fec/frontend/elements/media/media-progress-bar/media-progress-bar';
import { init as srfShareModalInit } from './srf-share-modal';
import { init as srfShameInit } from 'fef-legacy/assets/js/srf-shame';
import { init as consentManagerInit } from './consentManager';
import { init as embedConsentHandlerInit } from '@fec/frontend/modules/content/embed/embedConsentHandler';

import './SrfTouchFix';

// self initialising components
import * as pluginLoader from '../../webview/js/srf-plugin-loader';

/**
 * Images srf frontend framework
 */
const imagesContextFef = require.context(
  '@fec/assets/img',
  true,
  /\.(png|jpg|jpeg|gif|ico|svg|webp)$/,
);
imagesContextFef.keys().forEach(imagesContextFef);

/**
 * Images srf meteo
 */
const imagesContextMeteo = require.context(
  '../../meteo/img',
  true,
  /\.(png|jpg|jpeg|gif|ico|svg|webp)$/,
);
imagesContextMeteo.keys().forEach(imagesContextMeteo);

/**
 * Images srf-cms
 */
const imagesContextCms = require.context(
  '../../article/images',
  true,
  /\.(png|jpg|jpeg|gif|ico|svg|webp)$/,
);
imagesContextCms.keys().forEach(imagesContextCms);

/**
 * Common Assets
 */

/**
 * Analytics
 */
export function analytics() {
  consentManagerInit();
  srfAnalytics();
  srfSearchInit();
  deadmansSwitchInit();
}

/**
 * Navigation
 */
export function navigation() {
  searchInit();
  subnavInit();
}

/**
 * Basic
 */
export function base() {
  pluginLoader.init('web');
  transInit();
  a11yInit();
  embedConsentHandlerInit();
  flyingFocusInit();
  srfStaticBannerInit();
  popupInit();
  imageNgInit();
  teaserNgInit();
  teaserInfoUpdaterInit();
  srfBackgroundImageChangerInit(); // must be called before lazyloadInit!
  lazyloadInit();
  srfMeteoLazyloadInit();
  showDetailsInit();
  radioActionBarInit();
  modalInit();
  srfSongLog();
  srfThemeColorInit();
  broadcastInfoInit();
  teaserLabelInit();
  srfPodcastHistoryInit();
  SwipeableAreaInit();
  swipeableControlInit();
  srfMediaProgressDisplayInit();
  srfShareModalInit();
  srfShameInit();
}

/**
 * Common Web JS
 */
export function commonWeb() {
  analytics();
  navigation();
  base();
}
